import '@css/main.scss'

// Listen to scroll events and add a class to the header when the page is scrolled
const header = document.getElementById('main-menu') as HTMLElement | null;
if (header) {
    document.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            header.classList.add('scrolled')
        } else {
            header.classList.remove('scrolled')
        }
    })
} else {
    console.error('Header not found')
}
